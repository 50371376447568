import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { EventJoiner, EventJoinerStatus } from '@index/interfaces';
import { EventJoinerModel } from '@index/models/event-joiner';
import { GthUserModel } from '@sentinels/models';
import { UserService } from '@sentinels/services/firebase/user.service';
import { Timestamp } from 'firebase/firestore';
import { combineLatest, from, mergeMap, Observable, of, switchMap, toArray } from 'rxjs';
import { map, startWith, take } from 'rxjs/operators';

import { FirestoreService } from '../core/firebase.service';

export type EventUserJoinerModel = EventJoinerModel & {
  user?: GthUserModel;
  waitListDateTime?: Date;
};

export type EventAttendanceContract = {
  uid: string;
  joinerId: string;
  status: EventJoinerStatus;
};

@Injectable({
  providedIn: 'root',
})
export class JoinerService extends FirestoreService<EventJoinerModel> {
  protected basePath: string = 'events';

  constructor(
    firestore: AngularFirestore,
    private userService: UserService,
  ) {
    super(firestore);
  }

  getJoiners(eventId: string): Observable<EventUserJoinerModel[]> {
    return this.doc(eventId).collection<EventJoiner>('joiner')
      .valueChanges({ idField: 'id' })
      .pipe(
        map((joiners) => {
          // If joiners array is empty, emit an empty array
          if (!joiners || joiners.length === 0) {
            return [];
          }
          return joiners as EventJoinerModel[];
        }),
        switchMap((joiners) => {
          return from(joiners).pipe(
            mergeMap((joiner: EventUserJoinerModel) => {
              return combineLatest([
                of(joiner),
                this.userService.getUser$(joiner.player),
              ]).pipe(
                map(([joiner, user]) => {
                  joiner.user = user;
                  if (joiner.waitListDateTime) {
                    // eslint-disable-next-line max-len
                    joiner.waitListDateTime = (joiner.waitListDateTime as unknown as Timestamp).toDate();
                  }
                  if (joiner.createdAt) {
                    try {
                      if (typeof joiner.createdAt === 'number') {
                        (joiner as any).createdAt = new Date((joiner.createdAt as any));
                      } else {
                        // eslint-disable-next-line max-len
                        (joiner as any).createdAt = (joiner.createdAt as unknown as Timestamp).toDate();
                      }
                    } catch {
                      console.error('error parsing event joiner created at time');
                    }
                  }
                  return joiner;
                }),
              );
            }),
            take(joiners.length),
            toArray(),
          );
        }),
        startWith([]), // Emit an empty array as the first item
      );
  }

  async batchUpdateAttendance(
    eventId: string,
    attendance: EventAttendanceContract[]): Promise<boolean> {
    const requests = attendance.map(
      (a) => this.updateJoinerAsync(eventId, a.joinerId, { attendanceStatus: a.status }));

    const allSettled = await Promise.allSettled(requests);
    const requestCount = requests.length;
    let successCount = 0;

    allSettled.forEach((r) => {
      if (r.status === 'fulfilled') successCount++;
    });

    return requestCount === successCount;
  }

  async batchUpdateWaitlist(
    eventId: string,
    waitlist: string[]): Promise<boolean> {
    const today = new Date();
    // Users that are on the waitlist longer move up on the waitlist
    // Last user on waitlist should enter waitlist today
    // First user on waitlist should enter waitlist n days previous
    const waitlistCount = waitlist.length;
    const requests = waitlist.map(
      (joinerId: string, index) => {
        const waitListDateTime = new Date(today);
        waitListDateTime.setDate(waitListDateTime.getDate() - (waitlistCount - index));
        return this.updateJoinerAsync(eventId, joinerId, { waitListDateTime });
      });

    const allSettled = await Promise.allSettled(requests);
    const requestCount = requests.length;
    let successCount = 0;

    allSettled.forEach((r) => {
      if (r.status === 'fulfilled') successCount++;
    });

    return requestCount === successCount;
  }

  private async updateJoinerAsync(
    eventId: string,
    joinerId: string,
    data: any,
  ) {
    try {
      await this.doc(eventId)
        .collection<EventJoiner>('joiner')
        .doc(joinerId)
        .update(data);
      return true;
    } catch {
      console.error('Error updating joiner');
      return false;
    }
  }
}
